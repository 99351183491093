.nav {
  background: $primary-color;
  height: 80px;
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
}

.nav__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  @media(max-width: $bp-mobile) {
    margin: 0 $mobile-gutter;
  }
}

.nav__list {
  display: flex;
  flex: 3;
  gap: 26px;
  list-style: none;
}

.nav__list--item a {
  text-decoration: none;
  font-size: 14px;
  font-family: $body-font;
  font-weight: 300;
  text-transform: uppercase;
}

.nav__list--item a:hover {
  font-weight: 400;
}

.nav__list--item.active a {
  opacity: 1;
  font-weight: 700;
}

.nav__logo {
  margin-top: 10px;
  z-index: 10;
}

.nav__right-side {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px
}

.nav__right-side__social-icon {
  height: 20px
}

.mobile-nav {
  display: none;
}

.desktop-nav {
  @media(max-width: 920px) {
    display: none;
  }
}

@media(max-width: 920px) {
  .mobile-nav {
    display: block;
  }

  .mobile-nav__social-icons {
    flex: 3;
    display: flex;
    gap: 20px;
  }

  .nav__list--mobile {
    position: absolute;
    margin-right: -300px;
    visibility: hidden;
    transition: margin-right .5s ease;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    top: 0;
    bottom: 0;
  }

  .nav-active .nav__list--mobile {
    display: flex;
    flex-flow: column;
    justify-content: start;
    background: $primary-color;
    width: 100vw;
    right: 0;
    top: 0;
    height: 100vh;
    margin-right: 0px;
    visibility: inherit;
    z-index: 10;
    align-items: center;

    & img {
      height: 100px;
      width: 100px;
      object-fit: cover
    }

    & ul {
      margin: auto;
      transform: translateY(-50px);
      align-items: center;
      display: flex;
      flex-flow: column;
      gap: 10px
    }

    & li {
      text-align: center;
    }

    & .secondary-buttons {
      height: 100px;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .nav__list--mobile a {
    color: $background-color;
    text-decoration: none;
  }

  .hamburger {
    z-index: 20;
  }

  .nav-active .hamburger-inner,
  .nav-active .hamburger-inner::before,
  .nav-active.hamburger-inner::after {
    background: $background-color !important;

  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $background-color !important;
  }
}