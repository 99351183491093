form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 auto;
  }

  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid $text-color;
    font-size: 16px;
  }
  
  input[type="submit"] {
    background: $tertiary-color;
    border: 1px solid $text-color;
    color: $text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: $body-font;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 2px;
    transition: background .5s;
    padding: 10px;
  }
  
  input[type="submit"]:hover {
  
  }
  
  input:focus, textarea:focus {
    outline: none;
  
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="tel"]::placeholder,
  textarea::placeholder {
    font-family: $body-font;
    font-size: 14px;
  }

  .modal__form-container{
    padding: 80px;
    background: $background-color;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    form{
      display: flex;
      flex-flow: column;
      justify-content: center;
      gap: 10px;
    }
  }