@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lato:wght@300;400;700&family=PT+Serif+Caption&display=swap');
@font-face {
    font-family: "MyFont";
    src: url("../fonts/MyFont.otf") format("truetype");
  }

/* COLORS */
$primary-color: #426B58;
$feature-color:  #695F87;
$text-color: #333;
$background-color: #f4f5f7;
$secondary-text-color: white;
$tertiary-color: white;

/* TYPOGRAPHY */
$heading-font:'MyFont', serif;
$feature-font:'Great Vibes', cursive;
$body-font:'Lato', sans-serif;

/* SPACING */
$gutter: 80px;
$tablet-gutter: 60px; 
$mobile-gutter: 30px; 
$section-gap: 200px;
$tablet-section-gap: 80px;

// BREAKPOINTS
$bp-tablet: 1024px;
$bp-mobile: 450px;



