h1 {
    font-size: 32px;
    font-family: $heading-font;
    color: $feature-color;
    font-weight: 300;

    @media(max-width: 1000px) {
        font-size: 28px;
    }

    @media(max-width: 450px) {
        font-size: 24px;
    }
}

h3 {
    font-size: 22px;
    font-family: $heading-font;
    opacity: .7;
}

h2 {
    font-size: 40px;
    font-weight: 300;
    opacity: .7;

    @media(max-width: $bp-tablet) {
        font-size: 30px;
    }

    @media(max-width: $bp-mobile) {
        font-size: 22px;
    }
}

h4 {
    font-family: $body-font;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
    color: $primary-color;
    letter-spacing: 2.5px;
    text-transform: uppercase;

    @media(max-width: $bp-tablet) {
        font-size: 14px;
    }

    @media(max-width: $bp-mobile) {
        font-size: 10px;
    }
}

p {
    font-size: 17px;
    opacity: .8;
    font-family: $body-font;
    color: $text-color;
    font-weight: 300;
    line-height: 1.5;
}

.nav__list--item a {
    color: $secondary-text-color;
}