*, *::before, *::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    overflow-x: hidden;
}
a{
    text-decoration: none;
}

ul li{
    list-style: none;
}