@import "https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lato:wght@300;400;700&family=PT+Serif+Caption&display=swap";
@font-face {
  font-family: MyFont;
  src: url("MyFont.d511a87a.otf") format("truetype");
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow svg {
  fill: #000;
  width: 1.2em;
  height: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: .9;
}

.splide__arrow:disabled {
  opacity: .3;
}

.splide__arrow:focus-visible {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__pagination {
  z-index: 1;
  padding: 0 1em;
  position: absolute;
  bottom: .5em;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  opacity: .7;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  display: inline-block;
  position: relative;
}

.splide__pagination__page.is-active {
  z-index: 1;
  background: #fff;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9;
}

.splide__pagination__page:focus-visible, .splide.is-focus-in .splide__pagination__page:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: #0000;
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}

@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}

.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible, .splide.is-focus-in .splide__toggle:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__track--nav > .splide__list > .splide__slide {
  cursor: pointer;
  border: 3px solid #0000;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  top: auto;
  bottom: 1em;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  flex-direction: column;
  padding: 1em 0;
  display: flex;
  inset: 0 .5em 0 auto;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

ul li {
  list-style: none;
}

.container {
  margin: 0 80px;
}

@media (width <= 1024px) {
  .container {
    margin: 0 60px;
  }
}

h1 {
  color: #695f87;
  font-family: MyFont, serif;
  font-size: 32px;
  font-weight: 300;
}

@media (width <= 1000px) {
  h1 {
    font-size: 28px;
  }
}

@media (width <= 450px) {
  h1 {
    font-size: 24px;
  }
}

h3 {
  opacity: .7;
  font-family: MyFont, serif;
  font-size: 22px;
}

h2 {
  opacity: .7;
  font-size: 40px;
  font-weight: 300;
}

@media (width <= 1024px) {
  h2 {
    font-size: 30px;
  }
}

@media (width <= 450px) {
  h2 {
    font-size: 22px;
  }
}

h4 {
  color: #426b58;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

@media (width <= 1024px) {
  h4 {
    font-size: 14px;
  }
}

@media (width <= 450px) {
  h4 {
    font-size: 10px;
  }
}

p {
  opacity: .8;
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.5;
}

.nav__list--item a {
  color: #fff;
}

body {
  background: #426b58;
  width: 100vw;
  position: relative;
}

hr {
  color: #f4f5f7;
  opacity: .1;
}

.button {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 2px;
  background: none;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 35px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 300;
  transition: background .5s;
  display: flex;
}

.button:hover {
  color: #333;
  background: #fff;
  font-weight: bold;
}

.secondary-button {
  color: #f4f5f7;
  cursor: pointer;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 1px solid #f4f5f7;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 35px;
  margin-top: 20px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.hamburger {
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 15px;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger:hover, .hamburger.is-active:hover {
  opacity: .7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner:after {
  background-color: #695f87;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -2px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  background-color: #695f87;
  border-radius: 4px;
  width: 30px;
  height: 3px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx .hamburger-inner:before, .hamburger--3dx .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx.is-active .hamburger-inner {
  transform: rotateY(180deg);
  background-color: #0000 !important;
}

.hamburger--3dx.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx-r .hamburger-inner:before, .hamburger--3dx-r .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx-r.is-active .hamburger-inner {
  transform: rotateY(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy .hamburger-inner:before, .hamburger--3dy .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy.is-active .hamburger-inner {
  transform: rotateX(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy-r .hamburger-inner:before, .hamburger--3dy-r .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy-r.is-active .hamburger-inner {
  transform: rotateX(180deg);
  background-color: #0000 !important;
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dxy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy .hamburger-inner:before, .hamburger--3dxy .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy.is-active .hamburger-inner {
  transform: rotateX(180deg)rotateY(180deg);
  background-color: #0000 !important;
}

.hamburger--3dxy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy-r .hamburger-inner:before, .hamburger--3dxy-r .hamburger-inner:after {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  transform: rotateX(180deg)rotateY(180deg)rotateZ(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dxy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--arrow.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowalt .hamburger-inner:before {
  transition: top .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  transition: top .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  top: 0;
  transform: translate3d(-8px, -10px, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  transition: bottom .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  bottom: 0;
  transform: translate3d(-8px, 10px, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  transition: top .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  top: 0;
  transform: translate3d(8px, -10px, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  transition: bottom .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  bottom: 0;
  transform: translate3d(8px, 10px, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0)rotate(-45deg)scale(.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0)rotate(45deg)scale(.7, 1);
}

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:before, .hamburger--boring .hamburger-inner:after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--collapse .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear;
  top: -20px;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(-90deg);
}

.hamburger--collapse-r .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse-r .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear;
  top: -20px;
}

.hamburger--collapse-r .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(45deg);
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(90deg);
}

.hamburger--elastic .hamburger-inner {
  transition-duration: .275s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  top: 2px;
}

.hamburger--elastic .hamburger-inner:before {
  transition: opacity .125s .275s;
  top: 10px;
}

.hamburger--elastic .hamburger-inner:after {
  transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
  top: 20px;
}

.hamburger--elastic.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0)rotate(135deg);
}

.hamburger--elastic.is-active .hamburger-inner:before {
  opacity: 0;
  transition-delay: 0s;
}

.hamburger--elastic.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0)rotate(-270deg);
}

.hamburger--elastic-r .hamburger-inner {
  transition-duration: .275s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  top: 2px;
}

.hamburger--elastic-r .hamburger-inner:before {
  transition: opacity .125s .275s;
  top: 10px;
}

.hamburger--elastic-r .hamburger-inner:after {
  transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
  top: 20px;
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0)rotate(-135deg);
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  opacity: 0;
  transition-delay: 0s;
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0)rotate(270deg);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  left: -80px;
  transform: translate3d(80px, 80px, 0)rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  right: -80px;
  transform: translate3d(-80px, 80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic-r .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  left: -80px;
  transform: translate3d(80px, -80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  right: -80px;
  transform: translate3d(-80px, -80px, 0)rotate(45deg);
}

.hamburger--minus .hamburger-inner:before, .hamburger--minus .hamburger-inner:after {
  transition: bottom 80ms ease-out, top 80ms ease-out, opacity linear;
}

.hamburger--minus.is-active .hamburger-inner:before, .hamburger--minus.is-active .hamburger-inner:after {
  opacity: 0;
  transition: bottom 80ms ease-out, top 80ms ease-out, opacity 0s linear 80ms;
}

.hamburger--minus.is-active .hamburger-inner:before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner:after {
  bottom: 0;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner:before {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: ease;
  top: 10px;
}

.hamburger--slider .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  opacity: 0;
  transform: rotate(-45deg)translate3d(-5.71429px, -6px, 0);
}

.hamburger--slider.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0)rotate(-90deg);
}

.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner:before {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: ease;
  top: 10px;
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0)rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  opacity: 0;
  transform: rotate(45deg)translate3d(5.71429px, -6px, 0);
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0)rotate(90deg);
}

.hamburger--spin .hamburger-inner {
  transition-duration: .22s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin .hamburger-inner:before {
  transition: top .1s ease-in .25s, opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner:after {
  transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(225deg);
}

.hamburger--spin.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top .1s ease-out, opacity .1s ease-out .12s;
  top: 0;
}

.hamburger--spin.is-active .hamburger-inner:after {
  transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--spin-r .hamburger-inner {
  transition-duration: .22s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin-r .hamburger-inner:before {
  transition: top .1s ease-in .25s, opacity .1s ease-in;
}

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(-225deg);
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top .1s ease-out, opacity .1s ease-out .12s;
  top: 0;
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger--spring .hamburger-inner {
  transition: background-color 0s linear .13s;
  top: 2px;
}

.hamburger--spring .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 10px;
}

.hamburger--spring .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 20px;
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: .22s;
  background-color: #0000 !important;
}

.hamburger--spring.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 10px, 0)rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
  transition-duration: .13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--spring-r .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity linear;
  top: -20px;
}

.hamburger--spring-r .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity 0s linear .22s;
  top: 0;
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: rotate(90deg);
}

.hamburger--stand .hamburger-inner {
  transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms;
}

.hamburger--stand .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(.215, .61, .355, 1), background-color 0s linear .15s;
  transform: rotate(90deg);
  background-color: #0000 !important;
}

.hamburger--stand.is-active .hamburger-inner:before {
  transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  top: 0;
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner:after {
  transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  bottom: 0;
  transform: rotate(45deg);
}

.hamburger--stand-r .hamburger-inner {
  transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms;
}

.hamburger--stand-r .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(.215, .61, .355, 1), background-color 0s linear .15s;
  transform: rotate(-90deg);
  background-color: #0000 !important;
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  top: 0;
  transform: rotate(-45deg);
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  bottom: 0;
  transform: rotate(45deg);
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze .hamburger-inner:before {
  transition: top 75ms .12s, opacity 75ms;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 75ms .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top 75ms, opacity 75ms .12s;
  top: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  transition: bottom 75ms, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--vortex .hamburger-inner {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.hamburger--vortex .hamburger-inner:before, .hamburger--vortex .hamburger-inner:after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner:before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: rotate(765deg);
}

.hamburger--vortex.is-active .hamburger-inner:before, .hamburger--vortex.is-active .hamburger-inner:after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger--vortex-r .hamburger-inner {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.hamburger--vortex-r .hamburger-inner:before, .hamburger--vortex-r .hamburger-inner:after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner:before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:before, .hamburger--vortex-r.is-active .hamburger-inner:after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}

.nav {
  z-index: 10;
  background: #426b58;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
}

.nav__container {
  justify-content: space-between;
  align-items: center;
  height: 80px;
  display: flex;
}

@media (width <= 450px) {
  .nav__container {
    margin: 0 30px;
  }
}

.nav__list {
  flex: 3;
  gap: 26px;
  list-style: none;
  display: flex;
}

.nav__list--item a {
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}

.nav__list--item a:hover {
  font-weight: 400;
}

.nav__list--item.active a {
  opacity: 1;
  font-weight: 700;
}

.nav__logo {
  z-index: 10;
  margin-top: 10px;
}

.nav__right-side {
  flex: 3;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  display: flex;
}

.nav__right-side__social-icon {
  height: 20px;
}

.mobile-nav {
  display: none;
}

@media (width <= 920px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }

  .mobile-nav__social-icons {
    flex: 3;
    gap: 20px;
    display: flex;
  }

  .nav__list--mobile {
    visibility: hidden;
    flex-flow: column;
    justify-content: space-between;
    margin-right: -300px;
    transition: margin-right .5s;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .nav-active .nav__list--mobile {
    width: 100vw;
    height: 100vh;
    visibility: inherit;
    z-index: 10;
    background: #426b58;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    margin-right: 0;
    display: flex;
    top: 0;
    right: 0;
  }

  .nav-active .nav__list--mobile img {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }

  .nav-active .nav__list--mobile ul {
    flex-flow: column;
    align-items: center;
    gap: 10px;
    margin: auto;
    display: flex;
    transform: translateY(-50px);
  }

  .nav-active .nav__list--mobile li {
    text-align: center;
  }

  .nav-active .nav__list--mobile .secondary-buttons {
    align-items: center;
    gap: 10px;
    height: 100px;
    display: flex;
  }

  .nav__list--mobile a {
    color: #f4f5f7;
    text-decoration: none;
  }

  .hamburger {
    z-index: 20;
  }

  .nav-active .hamburger-inner, .nav-active .hamburger-inner:before, .nav-active.hamburger-inner:after, .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
    background: #f4f5f7 !important;
  }
}

.splide {
  text-align: center;
  background: #f4f5f7;
  padding: 100px 80px;
}

@media (width <= 1024px) {
  .splide {
    padding: 80px 60px;
  }
}

@media (width <= 450px) {
  .splide {
    padding: 100px 30px;
  }
}

.splide__slide {
  opacity: .7;
  font-family: Lato, sans-serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 1.5;
}

.splide__pagination {
  padding-top: 40px;
  position: relative;
}

.splide__pagination__page.is-active {
  opacity: .4;
  background: #000;
}

.splide__arrow {
  background: #f4f5f7;
}

.splide__pagination__page {
  opacity: .2;
  background: #000;
}

.quote-section__quote__p {
  width: 70%;
  margin: 0 auto 20px;
}

@media (width <= 1024px) {
  .quote-section__quote__p {
    width: 90%;
  }
}

cite {
  color: #426b58;
}

.quotation-mark svg {
  opacity: .7;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
}

#stars, .testimonials h2 {
  padding-bottom: 20px;
}

#overlay {
  z-index: 10;
  background-color: #000000e5;
  display: none;
  position: fixed;
  inset: 0;
}

#overlay.active {
  display: initial;
}

#modal {
  z-index: 11;
  border-radius: 10px;
  min-width: 50vw;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#modal.active {
  display: initial;
}

@media (width <= 507px) {
  #modal {
    width: 100vw;
    height: 100vh;
  }
}

#modal__content {
  background: #fff;
  border-radius: 10px;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

@media (width <= 1024px) {
  #modal__content {
    flex-flow: column;
  }
}

.modal__text {
  flex: 2;
  width: 500px;
  padding: 80px;
}

@media (width <= 1024px) {
  .modal__text {
    flex: initial;
    place-self: center;
    margin: auto;
    padding: 40px;
  }
}

@media (width <= 450px) {
  .modal__text {
    width: initial;
    padding: 30px;
  }
}

.modal__text p {
  padding: 15px 0;
}

.modal__close {
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (width <= 507px) {
  .modal__close {
    top: 60px;
  }
}

.modal__close img {
  height: 18px;
}

@media (width <= 1024px) {
  .modal__form-container {
    display: none !important;
  }
}

.gift-vouchers-available {
  padding-top: 5px;
  padding-bottom: 3px;
}

.footer {
  color: #fff;
  background: #426b58;
  padding: 0 0 20px;
}

.footer p {
  color: #f4f5f7;
}

@media (width <= 450px) {
  .footer {
    padding: 40px 30px;
  }
}

.footer__heading {
  opacity: 1;
  margin-bottom: 10px;
  font-weight: 300;
}

@media (width <= 1024px) {
  .footer__heading {
    font-size: 18px;
  }
}

.footer-content {
  flex-flow: row;
  align-items: center;
  margin-top: 0;
  display: flex;
}

@media (width <= 725px) {
  .footer-content {
    flex-flow: column;
    align-items: flex-start;
  }
}

.footer-content__social {
  flex-flow: row;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  padding: 40px 80px;
  display: flex;
}

@media (width <= 1024px) {
  .footer-content__social {
    gap: 10px;
    width: 100%;
    padding: 60px;
  }
}

@media (width <= 500px) {
  .footer-content__social {
    flex-flow: column;
  }
}

@media (width <= 450px) {
  .footer-content__social {
    gap: 10px;
    padding: 0;
  }
}

.subfooter {
  padding: 20px 80px;
}

.portfolio-link {
  color: #f5f514;
  text-decoration: underline;
}

.logoSide {
  flex-flow: column;
  order: 2;
  gap: 20px;
  display: flex;
}

@media (width <= 500px) {
  .logoSide {
    order: -1;
  }
}

.footer-content__social img {
  flex: 1;
}

.footer-content__social-row {
  width: 260px;
  font-size: 14px;
}

@media (width <= 1024px) {
  .footer-content__social-row {
    font-size: 12px;
  }
}

.footer-content__social-row a {
  color: #fff;
  align-items: center;
  gap: 10px;
  font-family: Lato, sans-serif;
  text-decoration: none;
  display: flex;
}

@media (width <= 1024px) {
  .footer-content__social-row a {
    gap: 5px;
  }
}

.icon-container {
  justify-content: center;
  width: 30px;
  display: flex;
}

.footer-content__social--icon-image {
  object-fit: contain;
  height: 20px;
}

#map {
  object-fit: cover;
  width: 50%;
  height: 345px;
}

@media (width <= 725px) {
  #map {
    order: -1;
    width: 100vw;
  }
}

footer img.logo {
  opacity: .8;
  object-fit: contain;
  width: 120px;
  margin-bottom: -10px;
  margin-left: -10px;
}

@media (width <= 500px) {
  footer img.logo {
    width: 100px;
  }
}

.social-rows {
  flex-flow: column;
  gap: 20px;
  padding: 20px 0;
  display: flex;
}

.footer .copy {
  text-align: center;
  padding-top: 20px;
}

form {
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  display: flex;
}

input[type="text"], input[type="email"], input[type="tel"], textarea {
  border: 1px solid #333;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

input[type="submit"] {
  color: #333;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 2px;
  background: #fff;
  border: 1px solid #333;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 300;
  transition: background .5s;
  display: flex;
}

input:focus, textarea:focus {
  outline: none;
}

input[type="text"]::placeholder, input[type="email"]::placeholder, input[type="tel"]::placeholder, textarea::placeholder {
  font-family: Lato, sans-serif;
  font-size: 14px;
}

.modal__form-container {
  background: #f4f5f7;
  border-radius: 0 10px 10px 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 80px;
  display: flex;
}

.modal__form-container form {
  flex-flow: column;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.logo {
  height: 100px;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 5;
  opacity: .2;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero {
  background: #426b58;
  height: calc(100vh - 120px);
  margin-top: 80px;
  display: flex;
}

.hero h1 {
  color: #fff;
}

@media (width <= 1024px) {
  .hero {
    height: initial;
    flex-flow: column;
  }
}

.hero__text {
  flex-flow: column;
  flex: 5;
  margin-top: 130px;
  margin-left: 80px;
  display: flex;
}

@media (width <= 1200px) {
  .hero__text {
    margin-top: 80px;
  }
}

@media (width <= 1024px) {
  .hero__text {
    text-align: left;
    order: 1;
    margin: 50px 60px;
  }
}

@media (width <= 450px) {
  .hero__text {
    text-align: left;
    align-items: flex-start;
    margin: 50px 30px;
  }
}

.hero__text--p {
  color: #fff;
  margin-top: 38px;
}

@media (width <= 1024px) {
  .hero__text--p {
    margin-top: 30px;
  }
}

.hero .button {
  margin-top: 75px;
}

@media (width <= 1024px) {
  .hero .button {
    margin-top: 55px;
  }
}

.hero img {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 100%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (width <= 1024px) {
  .hero img {
    clip-path: none;
    object-position: center -600px;
  }
}

@media (width <= 700px) {
  .hero img {
    object-position: center -400px;
  }
}

@media (width <= 545px) {
  .hero img {
    object-position: center -300px;
  }
}

@media (width <= 445px) {
  .hero img {
    object-position: center -200px;
  }
}

@media (width <= 400px) {
  .hero img {
    object-position: center -100px;
  }
}

.hero__image--container {
  width: 600px;
  overflow: hidden;
}

@media (width <= 1024px) {
  .hero__image--container {
    z-index: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    display: flex;
    overflow: hidden;
  }
}

.services {
  background: #fff;
  padding: 200px 80px;
}

@media (width <= 1024px) {
  .services {
    padding: 80px 60px;
  }
}

@media (width <= 450px) {
  .services {
    padding: 80px 30px;
  }
}

.services__section-title {
  margin-bottom: 35px;
}

.services__content {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
}

.services-card {
  flex: 1;
  min-width: 300px;
}

@media (width <= 450px) {
  .services-card {
    min-width: 200px;
  }
}

.services-card__image {
  object-fit: cover;
  width: 100%;
  height: 250px;
  display: block;
  position: relative;
}

.services-card__image-container {
  position: relative;
}

.services-card__title {
  margin-top: 20px;
  font-family: MyFont, serif;
  font-weight: 400;
}

.services-card__description {
  margin-top: 12px;
}

.about {
  background: #f4f5f7;
  position: relative;
}

.about__container {
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 50px, auto;
  color: #fff;
  padding: 80px;
  display: grid;
}

@media (width <= 1024px) {
  .about__container {
    grid-template-rows: 500px auto;
    grid-template-columns: 1fr;
    justify-items: center;
    margin: 0;
    padding: 0;
  }
}

@media (width <= 450px) {
  .about__container {
    padding: 0;
  }
}

.about__image {
  object-fit: cover;
  object-position: center 20%;
  grid-area: 1 / 1 / 9 / 7;
  width: 100%;
  height: 100%;
}

@media (width <= 1024px) {
  .about__image {
    z-index: 0;
    grid-row: 1 / 2;
    width: calc(100% - 120px);
    margin-top: 40px;
  }
}

@media (width <= 450px) {
  .about__image {
    width: calc(100% - 60px);
  }
}

.about__text {
  background: #426b58;
  grid-column: 5 / -1;
  grid-row-start: 3;
  height: fit-content;
  padding: 100px;
  box-shadow: 0 15px 25px #0000001a, 0 10px 10px #0000000d;
}

@media (width <= 1024px) {
  .about__text {
    grid-area: 5 / 1 / 6 / -1;
    padding: 100px 60px;
  }
}

@media (width <= 450px) {
  .about__text {
    padding: 100px 30px;
  }
}

.about__description {
  color: #fff;
  margin-top: 24px;
}

.about__heading {
  opacity: 1;
}

@media (width <= 1024px) {
  .about br {
    display: none;
  }
}

.prices {
  background: #fff;
  margin: 0;
  padding: 200px 80px;
}

@media (width <= 1024px) {
  .prices {
    padding: 80px 60px;
  }
}

@media (width <= 450px) {
  .prices {
    margin: 0;
    padding: 80px 30px;
  }
}

.prices__container {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  display: flex;
}

.prices-card {
  border: 1px solid #33333363;
  flex-flow: column;
  width: calc(33.3% - 20px);
  min-height: 320px;
  padding: 30px;
  display: flex;
}

.prices-card--add-on {
  background-color: #f6f8fa;
  flex-flow: column;
  align-items: start;
  gap: 10px;
  width: calc(33.3% - 20px);
  padding: 30px;
  display: flex;
}

@media (width <= 1024px) {
  .prices-card--add-on {
    width: calc(50% - 20px);
  }
}

@media (width <= 700px) {
  .prices-card--add-on {
    width: 100%;
  }
}

.prices-card--add-on h3 {
  font-size: 20px;
}

@media (width <= 1024px) {
  .prices-card {
    width: calc(50% - 20px);
  }
}

@media (width <= 700px) {
  .prices-card {
    width: 100%;
  }
}

.prices-card__title {
  font-weight: 300;
}

.prices-card__description {
  margin-top: 11px;
}

.prices-card__price {
  opacity: .9;
  font-family: MyFont, serif;
  font-size: 14px;
}

.price-card__bottom {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: auto;
  padding-top: 20px;
  display: flex;
}

.package-prices {
  margin: 0;
  padding: 0;
}

.package-prices h2 {
  padding-top: 40px;
}

.prices h2 {
  padding-bottom: 40px;
}
/*# sourceMappingURL=index.a40f7bd8.css.map */
