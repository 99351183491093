
// SPLIDE
.splide{
  background: $background-color;
  padding: 100px $gutter;
  text-align: center;
  @media(max-width: $bp-tablet){
    padding: 80px $tablet-gutter;
  }
  @media(max-width: $bp-mobile){
    padding: 100px $mobile-gutter;
  }
}
.splide__slide{
  font-family: $body-font;
  font-size: 26px;
  line-height: 1.5;
  font-weight: 300;
  opacity: .7;
 
}
.splide__pagination{
  position: relative;
  padding-top: 40px;
}
.splide__pagination__page.is-active{
  background: black;
  opacity: .4;
}
.splide__arrow{
  background: $background-color;
}

.splide__pagination__page{
  background:black;
  opacity: .2;
}
.quote-section__quote__p{
  margin-bottom: 20px;
  width: 70%;
  margin: 0 auto 20px;
  @media(max-width: $bp-tablet){
    width: 90%;
  }
}

cite{
  color: $primary-color;
}
.quotation-mark svg{
  height: 50px;
  width:100%;
  margin-bottom: 20px;
  opacity: .7;

}

#stars{
  padding-bottom: 20px;
}
.testimonials h2{
  padding-bottom: 20px;
}