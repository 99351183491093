.overlay {
    position: relative;
}

.overlay::before {
    content: "";
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .2;
    /* Adjust this value to set the opacity */
}