.button {
    background: transparent;
    border: 1px solid $secondary-text-color;
    color: $secondary-text-color;
    height: 35px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: $body-font;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 2px;
    transition: background .5s;
}

.button:hover {
    background: $secondary-text-color;
    color: $text-color;
    font-weight: bold;
}

.secondary-button {
    background: transparent;
    border: 1px solid $background-color;
    color: $background-color;
    height: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: $body-font;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    width: 120px;
    margin-top: 20px;

}