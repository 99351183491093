.prices {
    margin: 0;
    padding-top: 100px;
    padding: $section-gap $gutter;
    background: $tertiary-color;

    @media(max-width: $bp-tablet) {
        padding: $tablet-section-gap $tablet-gutter;
    }

    @media(max-width: $bp-mobile) {
        margin: 0;
        padding: $tablet-section-gap $mobile-gutter;
    }
}

.prices__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px 10px;
    display: flex;
}

.prices-card {
    min-height: 320px;
    display: flex;
    flex-flow: column;
    width: calc(33.3% - 20px);
    border: 1px solid rgba(51, 51, 51, 0.39);
    padding: 30px;
    &--add-on {
        display: flex;
        flex-flow: column;
        gap: 10px;
        align-items: start;
         width: calc(33.3% - 20px);
        background-color: rgb(246, 248, 250);
        padding: 30px;
        @media(max-width: $bp-tablet) {
            width: calc(50% - 20px);
        }
    
        @media(max-width: 700px) {
            width: 100%;
        }
    }
    &--add-on h3 {
       font-size: 20px;
    }

    @media(max-width: $bp-tablet) {
        width: calc(50% - 20px);
    }

    @media(max-width: 700px) {
        width: 100%;
    }
}

.prices-card__title {
    font-weight: 300;
}

.prices-card__description {
    margin-top: 11px;

}

.prices-card__price {
    font-size: 14px;
    font-family: $heading-font;
    opacity: .9;

}

.price-card__bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
    padding-top: 20px
}

.package-prices {
    padding: 0;
    margin: 0;
}

.package-prices h2 {
    padding-top: 40px;
}

.prices h2 {
    padding-bottom: 40px;
}