$logoBreak: 500px;

.footer {
    background: $primary-color;
    color: $secondary-text-color;
    padding: 0;
    padding-bottom: 20px;

    p {
        color: $background-color;
    }

    @media(max-width: $bp-mobile) {
        padding: 40px 30px 40px;
    }
}

.footer__heading {
    font-weight: 300;
    opacity: 1;
    margin-bottom: 10px;

    @media(max-width: $bp-tablet) {
        font-size: 18px;
    }
}

.footer-content {
    display: flex;
    align-items: center;
    flex-flow: row;
    margin-top: 0;

    @media(max-width: 725px) {
        flex-flow: column;
        align-items: flex-start;
    }

    &__social {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;
        flex: 1;
        padding: 40px 80px;

        @media(max-width: $bp-tablet) {
            gap: 10px;
            padding: 60px;
            width: 100%;

        }

        @media(max-width: $logoBreak) {
            flex-flow: column;
        }

        @media(max-width: $bp-mobile) {
            gap: 10px;
            padding: 0px;
        }
    }
}

.subfooter {
    padding: 20px 80px;
}
.portfolio-link {
    color: #f5f514;
    text-decoration: underline;
}

.logoSide {
    display: flex;
    flex-flow: column;
    gap: 20px;
    order: 2;

    @media(max-width: $logoBreak) {
        order: -1;
    }
}

.footer-content__social img {
    flex: 1;
}

.footer-content__social-row {
    font-size: 14px;
    width: 260px;

    @media(max-width: $bp-tablet) {
        font-size: 12px;
    }
}

.footer-content__social-row a {
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: $body-font;
    color: $secondary-text-color;
    text-decoration: none;

    @media(max-width: $bp-tablet) {
        gap: 5px;
    }
}

.icon-container {
    width: 30px;
    display: flex;
    justify-content: center;
}

.footer-content__social--icon-image {
    height: 20px;
    object-fit: contain;
}


#map {
    width: 50%;
    height: 345px;
    object-fit: cover;

    @media(max-width: 725px) {
        width: 100vw;
        order: -1;
    }
}

footer img.logo {
    margin-left: -10px;
    margin-bottom: -10px;
    width: 120px;
    opacity: .8;
    object-fit: contain;

    @media(max-width: $logoBreak) {
        width: 100px;
    }
}

.social-rows {
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding: 20px 0;
}

.footer .copy {
    text-align: center;
    padding-top: 20px;
    // padding: $gutter;
}