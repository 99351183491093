body {
    background: $primary-color;
    position: relative;
    width: 100vw;
}

// maybe delete hr 
hr {
    color: $background-color;
    opacity: .1;
}