.about{
    position: relative;
    background: $background-color;
}
.about__container{
    padding: $gutter;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-auto-rows: (50px, auto);
    color: $tertiary-color;
    @media(max-width: $bp-tablet){
        grid-template-columns: 1fr;
        grid-template-rows: 500px auto;
        margin:0;
        justify-items: center;
        padding: 0;
    }
    @media(max-width: $bp-mobile){
         padding: 0;
    }
}
.about__image{
    object-fit: cover;
    object-position: center 20%;
    height: 100%;
    width: 100%;
    grid-area: 1 / 1 / 9 / 7;
    @media(max-width: $bp-tablet){
      grid-row: 1/2;
      width: calc(100% - 120px);
      margin-top: 40px;
      z-index: 0;
    }
    @media(max-width: $bp-mobile){
      width: calc(100% - 60px);
    }
}
.about__text{
    padding:100px;
    height: fit-content;
    background: $primary-color;
    grid-column: 5/-1;
    grid-row-start: 3;
    box-shadow: 0 15px 25px rgba(0,0,0,0.1), 0 10px 10px rgba(0,0,0,0.05);
    @media(max-width: $bp-tablet){
        grid-area: 5 / 1 / 6 / -1;
        padding: 100px $tablet-gutter;
    }
    @media(max-width: $bp-mobile){
        padding: 100px $mobile-gutter;
    }
}

.about__description{
    color: $secondary-text-color;
    margin-top: 24px;
}
.about__heading{
    opacity: 1;
    @media(max-width: $bp-tablet){   
    }
}
.about br{
    @media(max-width: $bp-tablet){
        display: none;
    }
 
}