.services {
    padding: $section-gap $gutter;
    background: $tertiary-color;

    @media(max-width: $bp-tablet) {
        padding: $tablet-section-gap $tablet-gutter;
    }

    @media(max-width: $bp-mobile) {
        padding: 80px $mobile-gutter;
    }

    &__section-title {
        margin-bottom: 35px;
    }
}

.services__content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.services-card {
    flex: 1;
    min-width: 300px;

    @media(max-width: $bp-mobile) {
        min-width: 200px;
    }

    &__image {
        width: 100%;
        height: 250px;
        position: relative;
        object-fit: cover;
        display: block;
    }

    &__image-container {
        position: relative;
    }

    &__title {
        margin-top: 20px;
        font-family: $heading-font;
        font-weight: 400;
    }

    &__description {
        margin-top: 12px;
    }
}